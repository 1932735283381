<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3><br /><br />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <br />
        </div>

        <br />
        <br />
        <h3>ECOCARDIOGRAMA</h3>
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          
          {{ elemento }}
          <p></p>
          
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong>Dra {{ medico.nome == 'Adriana Melo (curso)'? 'Adriana Melo': medico.nome }}</strong> </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],

  mounted() {
    let folha = [];
    if(this.$route.query.via) this.via = this.$route.query.via;

    if (this.laudo.idadeGestacional)
      folha.push(
        `Idade gestacional: ${this.laudo.idadeGestacional} semanas. ${
          this.laudo.compativelIncompativel
            ? this.laudo.compativelIncompativel + '.'
            : ''
        } `
      );

    if (this.laudo.indicacao) folha.push(`Indicação: ${this.laudo.indicacao}.`);

    if (this.laudo.situs)
      folha.push(
        `Situs: ${this.laudo.situs}. ${
          this.laudo.situs2 ? this.laudo.situs2 + '.' : ''
        } `
      );

    if (this.laudo.posicaoCardiaca)
      folha.push(
        `Posição cardíaca: ${this.laudo.posicaoCardiaca}. ${
          this.laudo.posicaoCardiaca2 ? this.laudo.posicaoCardiaca2 + '.' : ''
        }`
      );

    if (this.laudo.eixoCardiaco)
      folha.push(
        `Eixo cardíaco: ${this.laudo.eixoCardiaco}. ${
          this.laudo.eixoCardiaco2 ? this.laudo.eixoCardiaco2 + '.' : ''
        } `
      );

    if (
      this.laudo.areaCardiaca &&
      this.laudo.areaCardiaca != 'Índice cardiotoráxico'
    )
      folha.push(
        `Área cardíaca: ${this.laudo.areaCardiaca}. ${
          this.laudo.areaCardiaca2 ? this.laudo.areaCardiaca2 + '.' : ''
        }`
      );

    if (
      this.laudo.areaCardiaca &&
      this.laudo.areaCardiaca == 'Índice cardiotoráxico'
    )
      folha.push(
        ` Área cardíaca: ${this.laudo.areaCardiaca} ${
          this.laudo.areaCardiacaCm
        }. ${this.laudo.areaCardiaca2 ? this.laudo.areaCardiaca2 + '.' : ''}`
      );

    if (this.laudo.juncaoAtrioventricular)
      folha.push(
        `Junção atrioventricular: ${this.laudo.juncaoAtrioventricular}.  ${
          this.laudo.juncaoAtrioventricular2
            ? this.laudo.juncaoAtrioventricular2 + '.'
            : ''
        }`
      );

    if (this.laudo.veiasPulmonares)
      folha.push(
        `Veias pulmonares: ${this.laudo.veiasPulmonares}. ${
          this.laudo.veiasPulmonares2 ? this.laudo.veiasPulmonares2 + '.' : ''
        }`
      );

    if (this.laudo.veiasSistemicas)
      folha.push(
        `Veias sistêmicas: ${this.laudo.veiasSistemicas}. ${
          this.laudo.veiasSistemicas2 ? this.laudo.veiasSistemicas2 + '.' : ''
        }`
      );

    if (this.laudo.forameOval)
      folha.push(
        `Forame oval: ${this.laudo.forameOval}. ${
          this.laudo.forameOval2 ? this.laudo.forameOval2 + '.' : ''
        }`
      );

    if (
      this.laudo.septoInterventricular &&
      this.laudo.septoInterventricular != 'comunicação interventricular medindo'
    )
      folha.push(
        `Septo interventricular: ${this.laudo.septoInterventricular}. ${
          this.laudo.septoInterventricular2
            ? this.laudo.septoInterventricular2 + '.'
            : ''
        } `
      );

    if (
      this.laudo.septoInterventricular &&
      this.laudo.septoInterventricular == 'comunicação interventricular medindo'
    )
      folha.push(
        ` Septo interventricular: ${this.laudo.septoInterventricular} ${
          this.laudo.septoInterventricularCm
        } mm, tipo via de entrada. ${
          this.laudo.septoInterventricular2
            ? this.laudo.septoInterventricular2 + '.'
            : ''
        }`
      );

    if (this.laudo.valvasAtrioventricularesAorticaPulmonar)
      folha.push(
        `Valvas atrioventriculares, aórtica e pulmonar: ${
          this.laudo.valvasAtrioventricularesAorticaPulmonar
        }. ${
          this.laudo.valvasAtrioventricularesAorticaPulmonar2
            ? this.laudo.valvasAtrioventricularesAorticaPulmonar2 + '.'
            : ''
        }`
      );

    if (
      this.laudo.troncoPulmonar &&
      this.laudo.troncoPulmonar != 'com dimensões reduzidas'
    )
      folha.push(
        `Tronco pulmonar: ${this.laudo.troncoPulmonar}. ${
          this.laudo.troncoPulmonar2 ? this.laudo.troncoPulmonar2 + '.' : ''
        }`
      );

    if (
      this.laudo.troncoPulmonar &&
      this.laudo.troncoPulmonar == 'com dimensões reduzidas'
    )
      folha.push(
        ` Tronco pulmonar: ${this.laudo.troncoPulmonar} ( Z-score= ${
          this.laudo.troncoPulmonarCm
        } ). ${
          this.laudo.troncoPulmonar2 ? this.laudo.troncoPulmonar2 + '.' : ''
        }`
      );

    if (this.laudo.aorta && this.laudo.aorta != 'com dimensões reduzidas')
      folha.push(
        `Aorta: ${this.laudo.aorta}. ${
          this.laudo.aorta2 ? this.laudo.aorta2 + '.' : ''
        }`
      );

    if (this.laudo.aorta && this.laudo.aorta == 'com dimensões reduzidas')
      folha.push(
        ` Aorta: ${this.laudo.aorta} ( Z-score= ${this.laudo.aortaCm} ). ${
          this.laudo.aorta2 ? this.laudo.aorta2 + '.' : ''
        }`
      );

    if (this.laudo.arcoAortico)
      folha.push(
        `Arco aórtico: ${this.laudo.arcoAortico}. ${
          this.laudo.arcoAortico2 ? this.laudo.arcoAortico2 + '.' : ''
        }`
      );

    if (this.laudo.patente)
      folha.push(
        `Canal arterial: Patente e apresenta fluxo de características normais direcionando do tronco pulmonar para a aorta descendente (IP ${
          this.laudo.patente
        } ). ${this.laudo.patente2 ? this.laudo.patente2 + '.' : ''}`
      );

    if (this.laudo.miocardio)
      folha.push(
        `Miocárdio: ${this.laudo.miocardio}.  ${
          this.laudo.miocardio2 ? this.laudo.miocardio2 + '.' : ''
        }`
      );
    if (this.laudo.comentariosAdcionaisFinal2)
      folha.push(`${this.laudo.comentariosAdcionaisFinal2}.`);

    if (folha.length > 0) folha.splice(0, 0, '');
    this.idadeGestacionalz = [...folha];
    folha = [];

    if (this.laudo.conclusao)
      folha.push(
        `${this.laudo.conclusao}. ${
          this.laudo.conclusao2 ? this.laudo.conclusao2 + '.' : ''
        }`
      );
    if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
    this.conclusaoz = [...folha];
    folha = [];

    if (this.laudo.observacao) folha.push(`${this.laudo.observacao}.`);
    if (folha.length > 0) folha.splice(0, 0, 'Observação');
    this.observacaoz = [...folha];
    folha = [];

    const todos = [
      ...this.idadeGestacionalz,
      ...this.conclusaoz,
      ...this.observacaoz,
    ];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 30));
    }
  },

  data() {
    return {
      idadeGestacionalz: [],
      conclusaoz: [],
      via: '',
      observacaoz: [],
      tamanhoElemento: [],
      titulos: ['Idade Gestacional', 'Conclusão', 'Observação'],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  h4 {
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -5px;

    margin-top: 5px;
    line-height: 6px;
    padding: 5px;
  }

  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }

  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    line-height: 5px;
  }

  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
